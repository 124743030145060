<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="PrayerBookingController"
        add-route-name="prayer-bookings-add"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col
              md="4"
              class="mb-1 mb-md-0"
            >
              <search
                placeholder="Title"
                :search-query.sync="eventsCriteria.searchQuery"
                @refresh="refreshTable"
              />
            </b-col>

            <b-col md="3">
              <custom-vue-select
                id="action-type-select"
                placeholder="Select action type"
                :value-reducer="option=>option.value"
                :options="[
                  {text:'Both',value:null},
                  {text:'Booking',value:'booked'},
                  {text:'Mark Attending',value:'mark_attending'}
                ]"
                text-field="text"
                :selected.sync="eventsCriteria.actionType"
              />
            </b-col>

            <b-col md="3">
              <date-range-filter :date-range.sync="eventsCriteria.dateRange" />
            </b-col>

            <b-col
              md="2"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <prayer-bookings-table
      ref="parentTable"
      v-bind="{
        fetchData:getPrayerEvents,
        currentPage:pagination.currentPage,
        perPage:pagination.perPage,
      }"
    />

    <pagination
      v-if="eventsCriteria.selectedEntityID"
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import PrayerBookingsTable from '@/common/components/PrayerBookings/PrayerBookingsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import paginationData from '@/common/compositions/common/paginationData'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'PrayerBookingList',
  components: {
    AddResource,
    DateRangeFilter,
    PrayerBookingsTable,
    Pagination,
    Search,
    SearchButton,
    CustomVueSelect,
  },
  data() {
    return {
      eventsCriteria: {
        selectedEntityID: this.$store.getters['mainEntity/getEntityId'],
        searchQuery: null,
        dateRange: [null, null],
        actionType: null,
      },
      loading: true,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getPrayerEvents() {
      const promise = this.$activities.get('internalops/prayer-activities', {
        params: {
          activity_name: this.eventsCriteria.searchQuery,
          entity_id: this.eventsCriteria.selectedEntityID,
          start_time: this.eventsCriteria.dateRange[0],
          end_time: this.eventsCriteria.dateRange[1],
          action_type: this.eventsCriteria.actionType,
          page: this.pagination.currentPage,
        },
      })
      return promise.then(res => {
        const prayerEvents = res.data.data.data

        this.pagination.totalRows = res.data.data.total
        return prayerEvents || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
