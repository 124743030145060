var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right mb-1"},[_c('add-resource',{attrs:{"resource-name":"PrayerBookingController","add-route-name":"prayer-bookings-add"}})],1),_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"header":"Search"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1 mb-md-0",attrs:{"md":"4"}},[_c('search',{attrs:{"placeholder":"Title","search-query":_vm.eventsCriteria.searchQuery},on:{"update:searchQuery":function($event){return _vm.$set(_vm.eventsCriteria, "searchQuery", $event)},"update:search-query":function($event){return _vm.$set(_vm.eventsCriteria, "searchQuery", $event)},"refresh":_vm.refreshTable}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('custom-vue-select',{attrs:{"id":"action-type-select","placeholder":"Select action type","value-reducer":function (option){ return option.value; },"options":[
                {text:'Both',value:null},
                {text:'Booking',value:'booked'},
                {text:'Mark Attending',value:'mark_attending'}
              ],"text-field":"text","selected":_vm.eventsCriteria.actionType},on:{"update:selected":function($event){return _vm.$set(_vm.eventsCriteria, "actionType", $event)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('date-range-filter',{attrs:{"date-range":_vm.eventsCriteria.dateRange},on:{"update:dateRange":function($event){return _vm.$set(_vm.eventsCriteria, "dateRange", $event)},"update:date-range":function($event){return _vm.$set(_vm.eventsCriteria, "dateRange", $event)}}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('search-button',{on:{"refresh":_vm.refreshTable}})],1)],1)],1)],1)],1),_c('b-row',{attrs:{"align-h":"center"}},[(_vm.loading)?_c('div',{staticClass:"loader"}):_vm._e()]),_c('prayer-bookings-table',_vm._b({ref:"parentTable"},'prayer-bookings-table',{
      fetchData:_vm.getPrayerEvents,
      currentPage:_vm.pagination.currentPage,
      perPage:_vm.pagination.perPage,
    },false)),(_vm.eventsCriteria.selectedEntityID)?_c('pagination',{attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }